<template>
  <div>
    <h2 v-html="$t('search_legalentity_title')"></h2>
    <v-text-field
      id="search_legalentity_legalentity_uid"
      v-model="searchCardStore.LegalEntity_uid"
      :label="$t('search_legalentity_legalentity_uid')"
      type="text"
      @keyup.enter="search"
    />
    <v-text-field
      id="search_legalentity_legalentity_agisid"
      v-model="searchCardStore.LegalEntity_agisId"
      :label="$t('search_legalentity_legalentity_agisid')"
      type="number"
      @keyup.enter="search"
    />

    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <v-text-field
        id="search_legalentity_legalentity_egid"
        v-model="searchCardStore.LegalEntity_egid"
        :label="$t('search_legalentity_legalentity_egid')"
        type="number"
        @keyup.enter="search"
      />
      <v-select
        id="search_legalentity_legalentity_status"
        v-model="searchCardStore.LegalEntity_status"
        :label="$t('search_legalentity_legalentity_status')"
        :items="LegalEntity_status"
        item-title="text"
        item-value="id"
        clearable
        @keyup.enter="search"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import baseSearchVue from './baseSearch.vue'
import { useSearchLegalEntityStore } from '@/store/SearchLegalEntityStore'

export default defineComponent({
  name: 'searchLegalEntity',
  extends: baseSearchVue,
  data() {
    return {
      LegalEntity_status: [
        { id: 1, text: this.$t('search_legalEntity_active') },
        { id: 0, text: this.$t('search_legalEntity_not_active') }
      ],
      expanded: false
    }
  },
  beforeMount() {
    this.setSearchCardStore(useSearchLegalEntityStore(), true)
  }
})
</script>
